<template>
  <v-col
    cols="12"
    sm="12"
  >
    <institutions :section="1" />
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Institutions from '@/components/Institutions';

export default {
  name: 'SettingsInstitutions',
  components: {
    Institutions,
  },
  data() {
    return {
      
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
